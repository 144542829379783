/* Copyright 2020 VMware, Inc. All rights reserved. -- VMware Confidential */

(function(){
   h5 = {
      // debug flag is used to include source maps for JS debugging and to display more console logs
      debug: false,
      // devMode flag is used to add the dev tools menu in the header
      devMode: false,
      // cypress flag is used to by-pass disable-pointer-events-feature-detection.js
      cypress: false,
      // useMockData is used for testing the client with json mock data
      mockData: false,
      isLoggedIn: false,
      isLogoutInProgress: false,
      pluginDeveloperMode: false,
      resourcesDirectory: "resources11654675"
   };

   /*
    * Saves query parameters and hash in the session storage under "h5_redirect_url"
    * key
    *
    * @param redirectUrl
    *       The URL to which the user should be redirected after a successful login.
    */
   h5.saveRedirectUrl = function(redirectUrl) {
      if (redirectUrl) {
         // Store redirect URL under "h5_redirect_url"
         // key the same key is also persisted in case of alert when the user session
         // has timed out, see responseErrorInterceptor

         // Safari, in Private Browsing Mode, looks like it supports localStorage but all calls to setItem
         // throw QuotaExceededError. We're going to detect this and just silently drop any calls to setItem
         // to avoid the entire page breaking, without having to do a check at each usage of Storage.
         if (typeof localStorage === "object") {
            try {
               localStorage.setItem("localStorage", 1);
               localStorage.removeItem("localStorage");
            } catch (e) {
               Storage.prototype._setItem = Storage.prototype.setItem;
               Storage.prototype.setItem = function() {};
               alert(h5.i18n.languageResource.Common["localStorage.notAvailableWarning"]);
            }
         }

         sessionStorage.setItem("h5_redirect_url", redirectUrl);
      }
   };

   /*
    * Reads the redirect URL from the session storage and redirects to it.
    */
   h5.redirectToSavedUrl = function() {
      var redirectUrl = sessionStorage.getItem("h5_redirect_url");
      if (redirectUrl) {
         sessionStorage.removeItem("h5_redirect_url");
         window.location.href = redirectUrl;
      }
   };

   function getResourceUrl(originalUrl) {
      var resourceUrl = "static/" + "resources11654675" + "/" + originalUrl;

      return resourceUrl;
   }

   function loadScript(url, async, defer) {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.async = async || false;
      script.defer = defer || false;
      script.src = url;
      document.head.appendChild(script);
   }

   function addStylesheetLink(linkConfig) {
      var link = document.createElement("link");
      link.type = "text/css";
      link.rel = linkConfig.rel || "stylesheet";

      if (linkConfig.url) {
         link.href = linkConfig.url;
      }

      if (linkConfig.id) {
         link.id = linkConfig.id;
      }

      link.disabled = (linkConfig.disabled === true);

      document.head.appendChild(link);
   }

   /*
    * Redirects the UI to the login page when the user is not authenticated and stores
    * the URL pathname, query parameters and hash in a session storage. The stored pathname, query
    * parameters and hash are used to be restored the original URL.
    *
    * @param isAuthenticated
    *       True if the user is authenticated otherwise false.
    */
   function redirectIfNeeded(isAuthenticated) {
      if (isAuthenticated) {
         h5.isLoggedIn = true;
      } else {
         sessionStorage.removeItem("h5_redirect_url");
         h5.saveRedirectUrl(window.location.href);
         window.location.href = "/ui/login";
         h5.bootstrapAngular = false;
      }
   }

   function getCssToLoad(cssDependencies, isEmergency) {
      var cssToLoad = [];

      if (cssDependencies) {
         for (var cssDependencyIndex in cssDependencies) {
            cssToLoad.push({url: cssDependencies[cssDependencyIndex]});
         }
      }

      if (!isEmergency) {
         // plugins/css url is protected, so loading it in emergency mode cause 401 response
         cssToLoad.push({
            url: "plugins/css"
         });
      }

      return cssToLoad;
   }

   function getThemesToLoad() {
      var themes = {
         light: "light",
         dark: "dark"
      };

      var currentTheme = getThemeFromLocalStorage(themes.light);
      currentTheme = currentTheme !== themes.light && currentTheme !== themes.dark ? themes.light : currentTheme;

      return ([
         { url: getResourceUrl("ng-next-app/font-awesome.min.css")},
         { url: getResourceUrl("ng-next-app/cds-core-global.min.css") },
         { url: getResourceUrl("ng-next-app/cds-core-theme-dark.min.css") },
         { url: getResourceUrl("ng-next-app/cds-core-shim.min.css") },
         // Expected base by Clarity styles
         { url: getResourceUrl("ng-next-app/normalize.min.css") },
         { url: getResourceUrl("ng-next-app/clr-icons.min.css") },
         {
            id: "clarity-theme",
            url: getResourceUrl("ng-next-app/clr-ui-a11y.min.css"),
            rel: getThemeRelAttributeValue(themes.light, currentTheme),
            disabled: themes.light !== currentTheme
         },
         {
            id: "forms-deprecated-light",
            url: getResourceUrl("ng-next-app/clr-forms-deprecated.min.css"),
            rel: getThemeRelAttributeValue(themes.light, currentTheme),
            disabled: themes.light !== currentTheme
         },
         {
            id: "clarity-theme-dark",
            url: getResourceUrl("ng-next-app/clr-ui-dark-a11y.min.css"),
            rel: getThemeRelAttributeValue(themes.dark, currentTheme),
            disabled: themes.dark !== currentTheme
         },
         {
            id: "forms-deprecated-dark",
            url: getResourceUrl("ng-next-app/clr-forms-dark-deprecated.min.css"),
            rel: getThemeRelAttributeValue(themes.dark, currentTheme),
            disabled: themes.dark !== currentTheme
         },
         {
            id: "vsphere-sprite",
            url: getResourceUrl("ng-next-app/vsphere-sprite-light.min.css"),
            rel: getThemeRelAttributeValue(themes.light, currentTheme),
            disabled: themes.light !== currentTheme
         },
         {
            id: "vsphere-sprite-dark",
            url: getResourceUrl("ng-next-app/vsphere-sprite-dark.min.css"),
            rel: getThemeRelAttributeValue(themes.dark, currentTheme),
            disabled: themes.dark !== currentTheme
         },
         {url: getResourceUrl("ng-next-app/styles.css")}
      ]);

   }


   function getScriptsToLoad(
         debug, locale, i18nResourcesVersion,
         segmentAppId, walkMeUrl) {
      var scriptsToLoad = [
         {
            url: "cache/h5-i18n?" + $.param([{
               name: "v",
               value: i18nResourcesVersion
            }])
         }, {
            url: getResourceUrl("ng-next-app/scripts.js"),
            defer: true
         }, {
            url: getResourceUrl("ng-next-app/runtime.js"),
            defer: true
         }, {
            url: getResourceUrl("ng-next-app/polyfills.js"),
            defer: true
         }, {
            url: getResourceUrl("ng-next-app/main.js"),
            defer: true
         }
      ];

      if (segmentAppId) {
         scriptsToLoad.push({
            url: getResourceUrl("js/segment-integration.js"),
            defer: true
         });
      }
      if (walkMeUrl) {
         scriptsToLoad.push({
            url: walkMeUrl,
            defer: true,
            async: true
         });
         window._walkmeConfig = {smartLoad: true};
      }


      return scriptsToLoad;
   }

   // Append url path as a query string to login request.
   function appendQueryString() {
      var queryParams = "";
      if (window.location.search === "" && window.location.pathname !== "/ui/") {
         queryParams = "?redirect_path=" + encodeURIComponent(window.location.pathname);
      } else if (window.location.search !== "" && window.location.pathname !== "/ui/") {
         queryParams = window.location.search + "&redirect_path=" + encodeURIComponent(window.location.pathname);
      } else {
         queryParams = window.location.search;
      }
      return queryParams;
   }

   function initH5(config, isEmergency) {
      var isAuthenticated = config && config.hasUserSession;

      if (isAuthenticated || isEmergency) {
         h5.isLoggedIn = true;
         h5.bootstrapAngular = true;
         initDevParameters();
         if (isExternalIdpUserLogin(config)) {
            setIdpUsernameInLocalStorage("idp-signin-cache", config.userName);
         }

         $.extend(h5, config);
         //Update the default (en_US) lang attribute of the html
         $(document).ready(function() {
            $("html").attr("lang", h5.lang);
            $("body")[0].dataset.theme = getThemeFromLocalStorage("light"); // emulate theme service, prior to bootstrap
         });

         var cssToLoad = getCssToLoad(h5.cssDependencies || [], isEmergency);

         for (var cssIndex in cssToLoad) {
            addStylesheetLink(cssToLoad[cssIndex]);
         }

         var scriptsToLoad = getScriptsToLoad(
               h5.debug, h5.locale || "en", h5.i18nResourcesVersion,
               h5.segmentAppId, h5.walkMeUrl);

         for (var scriptIndex in scriptsToLoad) {
            var scriptToLoad = scriptsToLoad[scriptIndex];

            var urlToLoad = h5.debug && scriptToLoad.debugUrl ?
                  scriptToLoad.debugUrl :
                  scriptToLoad.url;

            loadScript(urlToLoad, scriptToLoad.async, scriptToLoad.defer);
         }

      } else {
         sessionStorage.removeItem("h5_redirect_url");
         h5.saveRedirectUrl(window.location.href);
         window.location.href = "/ui/login" + appendQueryString();
         h5.bootstrapAngular = false;
      }
   }

   function isExternalIdpUserLogin(config) {
      return config.isIdpLocal === false;
   }

   function setIdpUsernameInLocalStorage(storParam, username) {
      localStorage.setItem(storParam, username);
   }



   function initThemeStyles() {
      var linkToLoad = getThemesToLoad();

      for (var linkIndex in linkToLoad) {
         addStylesheetLink(linkToLoad[linkIndex]);
      }
   }



   function getThemeFromLocalStorage(defaultTheme) {
      var themeKey = "vsphere-client-preferred-theme";

      try {
         return JSON.parse(localStorage.getItem(themeKey)) || defaultTheme;
      } catch (e) {
         return defaultTheme;
      }
   }

   function getThemeRelAttributeValue(theme, currentTheme) {
      return theme === currentTheme ? "stylesheet" : "alternate stylesheet";
   }

   function initDevParameters()  {
      // This initializes the h5.debug and h5.devMode flags based on query parameters, and persists
      // them in browser's localstorage for future sessions:
      // - adding ?debug=true when starting H5C turns debug ON and saves this state.
      // - any new session started later with have debug ON
      // - to turn debug OFF, either add ?debug=false, or remove the cookie manually.
      initPersitentParam("debug", "useDebug");
      initPersitentParam("devMode", "useDevMode");
      initPersitentParam("cypress", "useCypress");
      initPersitentParam("mockData", "useMockData");

      // Can this flag be combined with devMode?
      h5.pluginDeveloperMode = window.location.search.indexOf("pluginDeveloperMode=true") !== -1;
   }

   function initPersitentParam(urlParam, storedParam) {
      var turnParamOn = window.location.search.indexOf(urlParam + "=true") !== -1;
      var turnParamOff = window.location.search.indexOf(urlParam + "=false") !== -1;
      if (window.localStorage.getItem(storedParam) === "true") {
         if (turnParamOff) {
            h5[urlParam] = false;
            window.localStorage.removeItem(storedParam);
         } else {
            h5[urlParam] = true;
         }
      } else if (turnParamOn) {
         h5[urlParam] = true;
         window.localStorage.setItem(storedParam, "true");
      }
      if (h5[urlParam]) {
         console.warn(urlParam + " flag is on at this IP. Turn it off by reloading with param ?" + urlParam + "=false");
      }
   }

   var isEmergency = location.pathname.startsWith("/ui/offline-access");
   if (!window.location.search && !window.location.hash && !isEmergency) {
      // Query string and hash are missing. Try to restore it.
      h5.redirectToSavedUrl();
   }

   $.ajax({
      type: "GET",
      dataType: "json",
      url: "config/h5-config",
      data: {
         debug: window.location.search.indexOf("debug=true") !== -1
      },
      xhrFields: {
         withCredentials: true
      },
      headers: {
         "Cache-Control": "no-cache",
         "Pragma": "no-cache",
         "If-Modified-Since": "Mon, 1 Jan 2000 00:00:00 GMT"
      },
      success: function(config) { initH5(config, isEmergency); }
   });

   initThemeStyles();

}());
